@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Product_Sans_Regular";
  src: local("Product_Sans_Regular"),
    url("./Amiri-Regular.ttf") format("truetype");
}
* {
  box-sizing: border-box;
}

/* body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  background-color: #fcf3f6;
} */
/* body {
  font-feature-settings: "tnum";
  background-color: #fff;
  color: rgba(0, 0, 0, .85);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
} */
.text-white {
  color: white;
}
h1 {
  font-size: 2.5rem;
  color: #2c292b;
}

h2 {
  font-size: 1.5rem;
  color: #2c292b;
}

a {
  color: inherit !important;
  text-decoration: inherit !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

h3 {
  font-size: inherit !important;
}

.table > :not(:first-child) {
  border-top: 0px solid currentColor !important;
}

ol,
ul {
  padding-left: 0rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
  margin-bottom: 0em !important;
}

/**for the news ticker */
/**cp ticker*/
.news-wrapper {
  width: 100%;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  background-color: rgba(137, 165, 142, 0.2);
  color: #fff;
  font-family: "Roboto";
}
.news-wrapper::before {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  z-index: 1;
  content: "";
  background: linear-gradient(
    90deg,
    #fff,
    transparent 25px,
    transparent calc(100% - 25px),
    #fff
  );
}

.news-inner {
  animation-duration: var(--animationDuration);
  animation-timing-function: linear;
}

.news-inner.moving {
  animation-name: moveticker;
}
.news-wrapper:hover .news-inner.moving {
  animation-play-state: paused;
}

.element {
  display: inline-block;
  padding: 0 20px;
  font-family: "Quicksand";
}

@keyframes moveticker {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(var(--animationDistance));
  }
}

/* .news-ticker {
  height: 30px;
  background-color: rgba(137, 165, 142, 0.2);
  overflow: hidden;
  color: #fff;
  font-weight: bold;
  padding: -5px;
  white-space: nowrap;
}

.ticker-wrap {
  display: inline-block;
  animation: ticker 120s linear infinite;
}

.ticker-move {
  display: inline-block;
  animation: ticker 120s linear infinite;
}

.ticker-item {
  display: inline-block;
  padding: 0 20px;
}

@keyframes ticker {
  0% {
    transform: translateX(130%);
  }
  100% {
    transform: translateX(-100%);
  }
} */
/* 
.news-ticker:hover .ticker-wrap,
.news-ticker:hover .ticker-move {
  animation-play-state: paused;
} */

/**news ticker ends here*/

.container_custom {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  /* padding-left: 0.75rem;
  padding-right: 0.75rem; */
}

@media (min-width: 1280px) {
  .container_custom {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .container_custom {
    /* padding-left: 1.25rem;
    padding-right: 1.25rem; */
  }
}

.p-15 {
  padding: 1.25rem !important;
}
.uppercase {
  text-transform: uppercase !important;
}

.semibold {
  font-weight: 600 !important;
}
.dropdown:hover .dropdown-menu {
  display: block;
}

.slick-slider {
  /* spacing in between each slide */
  margin-top: 20px;
}
.hl {
  background: #ffc600;
}
.bg {
  /* The image used */
  background-image: url("./mainbg.png");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg_color {
  background-color: #89a58e;
}
.topNav_ul__F0tE5 {
  background-color: #fff;
  color: #89a58e;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0 !important;
}
.nav-menu {
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
}

.nav-menu > * {
  width: 50%;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
}

.nav-menu .nav-menu-phone-number {
  background: #fff;
  width: 50%;
  margin-left: -10px;
  color: #89a58e !important;
  font-weight: 700;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.topNav_ul__F0tE5:last-child span {
  display: inline-block;
  /* -webkit-transform: skew(25deg);
  transform: skew(25deg); */
  color: #fff;
}
.nav-menu li span {
  background-color: transparent;
  transform: skew(25deg);
  color: #fff;
  font-weight: 700;
  font-size: 0.75rem;
}

.nav-menu .nav-menu-welcome-text {
  background-color: #89a58e;
  position: relative;
  right: 20px;
  -webkit-transform: skew(-25deg);
  transform: skew(-25deg);
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-2-container {
  width: 70%;
  margin: auto;
  gap: 10%;
}

.nav-2-container .nav-search-container {
  width: 60%;
}

.nav-logo-icon {
  width: 10%;
}

.nav-2-container .language-select-drop-down {
  background: transparent;
  appearance: none;
  text-align: center;
  color: white;
  cursor: pointer;
  padding: 5px;
  font-size: 1.2rem;
}
.nav-2-container .language-select-drop-down:focus {
  background: transparent !important;
  outline: none;
}

.nav-3-container {
  background-color: rgba(137, 165, 142, 0.2);
  display: flex;
  justify-content: center;
  padding: 5px;
}

.nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav {
  text-align: center;
}

.nav ul {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
}

.nav ul li {
  position: relative;
  float: left;
}

.nav ul li + li {
  margin-left: 1px;
}

.nav ul li a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding: 11px 10px;
  -webkit-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.nav ul li > ul {
  display: none;
  position: absolute;
  width: 150px;
  top: 100%;
  left: -1px;
  z-index: 9;
  text-align: left;
  background-color: #89a58e;
  border-radius: 5px;
  color: white;
}

.nav ul li > ul li {
  float: none;
  margin: 0;
}

.nav ul li > ul li a {
  display: block;
  border-top: 1px solid #555;
}

.nav ul li > ul li a:hover {
  border-top: 1px solid #646464;
  z-index: 9;
  background-color: #527558;
}

.nav ul li.active {
  pointer-events: none;
}

/**swiper category homepage*/
.home-swiper-container {
  width: 100%;
  position: relative;
  margin: 10% auto;
}
.category-swiper-container {
  width: 100%;
  height: 200px;
  position: absolute;
  background-color: transparent;
  bottom: -20%;
  z-index: 10;
  margin: auto;
}
.category-swiper-container .category-swiper-items-container {
  width: 80%;
  margin: auto;
}
.all-categories-card {
  width: 330px;
}
@media only screen and (max-width: 650px) {
  .category-swiper-container {
    margin-bottom: 10% !important;
  }
  .categories-page-container {
    width: 100% !important;
  }
  .all-categories-card {
    width: 50% !important ;
  }
  .all-categories-card img {
    object-fit: contain !important;
  }
  .nav-menu .nav-menu-phone-number,
  .nav-menu-welcome-text {
    font-size: 0.6rem !important;
  }
  .nav-2-container {
    flex-wrap: wrap;
    width: 100% !important ;
    justify-content: center;
    margin: 10px 0;
  }
  .nav-2-container .nav-logo-icon {
    width: 10% !important;
    margin-right: 5px !important;
  }
  .nav-2-container .nav-search-container {
    width: 60% !important;
  }
  .nav-search-container .nav-search-input {
    font-size: 0.8rem !important;
  }
  .nav-2-container .language-select-container {
    margin-left: 10px !important;
  }
  .nav-2-container .language-select-drop-down {
    padding: 0px !important;
    font-size: 0.8rem;
    width: 35px;
  }
  .new-products-container {
    width: 100% !important;
  }
  .product-card-img {
    object-fit: cover !important;
    height: 120px;
    width: 100%;
  }
  .product-card-tag {
    font-size: 0.6rem;
  }
  .product-card-description {
    font-size: 0.9rem;
  }
  .product-cards-items {
    width: 50% !important;
  }
  .view-all-btn {
    position: relative !important;
  }
  .about-us-img {
    width: 50% !important;
    margin: auto;
  }
  .about-us-text-title {
    margin-top: 15px !important;
    margin-bottom: 0px !important;
  }
  .about-us-text p {
    margin-top: 1rem !important;
  }

  .branches-items-container {
    display: flex;
    flex-direction: column !important;
    width: 90% !important;
  }
  .branch-item iframe {
    width: 100% !important;
  }
  .new-categories-container {
    width: 100% !important;
  }
  .footer-main-container {
    flex-wrap: wrap;
    /* justify-content: space-around !important; */
    gap: 20px !important;
  }
  .footer-main-container li {
    width: 45% !important;
    justify-content: flex-start;
  }

  .footer-first-row-container {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    width: 100% !important;
  }
  .footer-first-row-container .footer-logo {
    width: 100% !important;
  }
  .about-us-items {
    flex-direction: column;
    align-items: center;
  }
  .footer-second-row {
    width: 100% !important;
  }

  .all-products-item {
    width: 50% !important;
  }
  .single-page-text-container {
    width: 90% !important;
    margin: auto;
  }
  .single-product-description {
    margin-top: 15px;
  }
  .related-product-img {
    height: fit-content;
    margin-top: 30%;
  }
  .mobile-mt {
    margin-top: 30% !important;
  }
}

@media only screen and (max-width: 400px) {
  .footer-main-container li {
    width: 100% !important;
  }
}

/**footer terms and privacy */
.footer-priv-term {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.footer-prv {
  font-size: 0.8rem;
  font-weight: 500;
  font-family: "Roboto";
  cursor: pointer;
}
.footer-prv:hover {
  color: #ffc600;
}

/**for the new product div*/
.section-container {
  position: relative;
}
.view-all-btn {
  position: absolute;
  top: 0;
}
.new-products-container {
  width: 80%;
  margin: auto;
}

/**product cards-container*/
.product-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.product-cards-container .product-cards-items {
  width: 330px;
}

/**new categories container*/
.categories-page-container {
  width: 90%;
  margin: auto;
}
.home-category {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.home-category-item {
  width: 330px;
}
.new-categories-container {
  width: 80%;
  margin: auto;
}

/*projectSwiperCards-container*/
.projectSwiperCards-container {
  width: 90%;
  margin: auto;
}

/**about us section*/
.about-us-container {
  width: 100%;
  background-color: #fff;
  margin-bottom: 5%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.styledTitle_titleH1__iVF-P {
  width: 100%;
  text-align: center;
  color: #89a58e;
  font-family: Raleway, sans-serif;
  font-size: 40px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  position: relative;
  transition: all 0.4s ease 0s;
}
.styledTitle_titleH1__iVF-P::before {
  background-color: #89a58e;
  bottom: -15px;
  content: "";
  display: block;
  height: 5px;
  left: 50%;
  margin-left: -14px;
  position: absolute;
  width: 28px;
}
.about-us-items {
  display: flex;
  width: 80%;
  margin: 5% auto;
}
.about-us-items .about-us-img {
  object-fit: contain;
}
.about-us-items .about-us-text {
  width: 100%;
}

/**our branch starts here*/
.our-branches-container {
  margin-top: 5%;
  width: 100%;
  background-color: #fff;
  margin-bottom: 5%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.branches-items-container {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5% auto;
  gap: 5%;
}
.branch-item {
  padding: 5px;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.branch-item p {
  text-align: center;
}

/**End of our branch**/

/*footer starts here*/
.footer-main-container {
  display: flex;
  justify-content: center;
  gap: 50px;
  font-size: 0.85rem !important;
}
.footer-first-row-container {
  width: 90%;
  display: flex;
  justify-content: center;
  /* margin-bottom: 0%; */
}
.footer-first-row-container .footer-logo {
  width: fit-content;
}
.footer-item-text {
  width: 150px;
}
.footer-contact-us {
  display: flex;
  flex-direction: column;
  gap: 5px;
  white-space: nowrap;
}

/*footer ends here*/

/**all projects starts here*/
.all-project-container {
  display: flex;
  flex-wrap: wrap;
}
/**all projects ends here */

/**all products starts here*/
.all-products-container {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
}
.all-products-item {
  width: 20%;
}
/**all product ends here */
