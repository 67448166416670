.App {
    text-align: center;
    color: Green;
}
*{
/* font-family: "ArbFONTS-Hacen-Liner-XL.ttf" !important; */
letter-spacing: .08rem;
}
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

